<template>
  <div class="register">
    <div class="container">
      <div class="title">
        <div>{{ isChinese ? "忘记密码" : "Forget your password" }}</div>
        <img src="@/assets/images/close.png" alt="" class="close"  @click="handleClose"/>
      </div>
      <el-form
        ref="ruleFormRef"
        :model="ruleForm"
        :rules="rules"
        label-width="120px"
        :class="['demo-ruleForm', { 'en-ruleForm': !isChinese }]"
      >
        <el-form-item prop="email">
          <el-input
            v-model="ruleForm.email"
            :placeholder="isChinese ? '请输入邮箱' : 'Enter your email address'"
          />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="ruleForm.password"
            :placeholder="
              isChinese
                ? '请输入新密码，必须包含数字、大小写字母6-16位'
                : 'Create a new password(6-16 characters)'
            "
            type="password"
            show-password
          />
        </el-form-item>
        <el-form-item prop="password_confirmation">
          <el-input
            v-model="ruleForm.password_confirmation"
            :placeholder="isChinese ? '请再次确认密码' : 'Confirm your password'"
            type="password"
            show-password
          />
        </el-form-item>
        <el-form-item prop="code" class="code">
          <el-input
            v-model="ruleForm.code"
            :placeholder="
              isChinese ? '请输入邮箱验证码' : 'Enter verfication code'
            "
          />
          <el-button plain v-if="isChinese" :disabled="codeBtnisAble" @click="getCode(ruleFormRef)">{{codeText}}</el-button>
          <el-button plain v-else class="enbtn" @click="getCode(ruleFormRef)" v-html="codeText" :disabled="codeBtnisAble"
            ></el-button
          >
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm(ruleFormRef)">{{
            isChinese ? "提交" : "Submit"
          }}</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { defineComponent, ref, reactive } from "vue";
import { Ajax, MessageTips } from "@/utils";
import { useRouter } from "vue-router";
export default defineComponent({
  components: {},
  setup() {
    const route = useRouter()
    const ruleFormRef = ref();
    const ruleForm = reactive({
      email: "",
      password: "",
      password_confirmation: "",
      code: "",
    });
    const isChinese = ref(
      // localStorage.getItem("language") === "en" ? false : true
      false
    );
    const codeText = ref(isChinese.value ? '获取邮箱验证码' : `Send <div>verfication code</div>`)
    const codeBtnisAble = ref(false)
    const maxTime = ref(60)
    const checkEmail = (rule, value, callback) => {
      const reg = /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      const index = value.indexOf('@')
      // console.log('-----index', index, value.slice(index + 1))
      // reg.test(value)
      if (index !== -1 && value.slice(index + 1).trim()) {
        callback();
      } else if (!value.length) {
        const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
      } else {
        const text = isChinese.value ? "邮箱格式错误" : "E-mail error!";
        callback(new Error(text));
      }
    };

    const checkPassword = (rule, value, callback) => {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/;
      if (reg.test(value)) {
        callback();
      }if(!value.trim().length){
          const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
        }  else {
        let text = isChinese.value
          ? "请输入新密码，必须包含数字、大小写字母6-16位"
          : `The password needs to meet all the requirements below:
a total of 6-16 characters with a minimum of one uppercase letter, one lowercase letter and one number. No space and no Chinese character.`;
        callback(new Error(text));
      }
    };

    const checkPassword2 = (rule, value, callback) => {
      const reg = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,16}$/;
      if(!value.trim().length){
          const text = isChinese.value
          ? "不能为空"
          : "This field cannot be left blank.";
        callback(new Error(text));
        } else {
          if (value.length && (value !== ruleForm.password)) {
            const text = isChinese.value
              ? "两次密码不一致"
              : "The two passwords do not match.";
            callback(new Error(text));
          } else if (!reg.test(value)) {
            let text = isChinese.value
              ? "请输入新密码，必须包含数字、大小写字母6-16位"
              : `The password needs to meet all the requirements below:
a total of 6-16 characters with a minimum of one uppercase letter, one lowercase letter and one number. No space and no Chinese character.`;
            callback(new Error(text));
          }
        }
        
     
    };
    const rules = reactive({
      email: [
        {
          trigger: "blur",
          validator: checkEmail,
        },
      ],
      password: [
        {
          trigger: "blur",
          validator: checkPassword,
        },
      ],
      password_confirmation: [
        {
          trigger: "blur",
          validator: checkPassword2,
        },
      ],
      code: [
        {
          required: true,
          message: isChinese.value ? "请输入验证码" : 'This field cannot be left blank.',
          trigger: "blur",
        },
      ],
    });

    const getCode = (formEl) => {
      if (!formEl) return;
      formEl.validateField('email', (valid) => {
        // console.log('valid=======', valid)
        if (!valid) {
          // console.log("submit!");
          getCodeRequest()
        } else {
          // console.log("error submit!");
          return false;
        }
      });
     
    }

    const getCodeRequest = async() => {
      codeText.value = isChinese.value ? '加载中' : 'loading'
      codeBtnisAble.value = true
      const res = await Ajax.POST({
        url: '/api/sendEmailCode',
        data: {
          email: ruleForm.email
        }
      })
      if (res.data.code === 10000){
        let siv = setInterval(() => {
        maxTime.value = maxTime.value - 1,
        codeText.value = `${maxTime.value}s`
        if (maxTime.value === 1){
            clearInterval(siv)
            codeText.value = isChinese.value ? '重新获取验证码' : `Send <div>verfication code</div>`
            codeBtnisAble.value = false
            maxTime.value = 60
          }
        }, 1000)
        const text = isChinese.value ? '验证码已发送至您的邮箱，请注意查收。' : 'A verification email has been sent to your email, please check your email box.'
        MessageTips.success(text)
      }
    }

    const forgetPwd = async() => {
      const res = await Ajax.POST({
        url: '/api/forgetPwd',
        data: ruleForm
      })
      if (res.data.code === 10000){
        route.push('/login')
      }
    }
    const submitForm = (formEl) => {
      if (!formEl) return;
      let validateList = []
      formEl.validateField(['email', 'password', 'password_confirmation', 'code'], (valid) => {
        // console.log('valid=======', valid)
        validateList.push(valid)
      });
      if (validateList.every((item) => item === '')) {
          forgetPwd()
      } else {
        // console.log("error submit!");
        return false;
      }
    };

    const handleClose = () => {
      route.back(-1)
    }
    return {
      ruleFormRef,
      ruleForm,
      rules,
      submitForm,
      isChinese,
      codeText,
      codeBtnisAble,
      maxTime,
      getCode,
      getCodeRequest,
      forgetPwd,
      handleClose
    };
  },
});
</script>
<style lang="scss" scoped>
.register {
  width: 100%;
  height: 100vh;
  background: rgba($color: #000000, $alpha: 0.4);
  .container {
    width: 500px;
    min-height: 507px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin-top: -253px;
    margin-left: -250px;
    background: #ffffff;
    border-radius: 8px;
    padding: 38px;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 43px;
      > div {
        font-size: 24px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #000000;
      }
      img {
        cursor: pointer;
      }
    }
    :deep(.el-form-item) {
      margin-bottom: 50px;
      .el-form-item__content {
        margin-left: 0 !important;
        .el-input__inner {
          border: none;
          border-radius: 0;
          border-bottom: 1px solid #eeeeee;
          padding: 0;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #9b9b9b;
        }
        .el-form-item__error {
          // font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
        }
        .el-button--primary {
          width: 100%;
          height: 60px;
          background: #e34c3e;
          border-radius: 4px;
          font-size: 18px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          border: none;
          margin-top: 45px;
        }
      }
    }
    .en-ruleForm {
      :deep(.el-form-item) {
        .el-form-item__content {
          .el-input__inner{
            font-size: 15px;
          }
        }
      }
    }
    .code {
      .el-form-item__content {
        position: relative;
        .el-button {
          position: absolute;
          right: 0;
          bottom: 9px;
          width: 153px;
          height: 38px;
          border-radius: 4px;
          border: 1px solid #e34c3e;
          font-size: 16px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #e34c3e;
          padding: 0;
        }
      }
    }
  }
}
</style>
